import React from 'react';
import './Newsection.css'
export default function Newsection() {
  const sections = [
    {
      imgSrc: "https://www.idrakai.com/static/media/sub1.efa9d24525559ee38786e46eb761855f.svg",
      title: " VISION TAKES FLIGHT",
      description: "TAJIR stands at the AI vanguard, morphing visionary concepts into transformative solutions."
    },
    {
      imgSrc: "https://www.idrakai.com/static/media/sub2.c01d95f3bc703b53ab8f1d5445c823d2.svg",
      title: "EMPOWERMENT HUB",
      description: "We gift individuals the keys to soar, evolve, and turn their business dreams into realities."
    },
    {
      imgSrc: "https://www.idrakai.com/static/media/sub3.998d313cbf07eed0c42d9daadbdaf8ce.svg",
      title: "JUST ABOUT INNOVATION",
      description: "We're passionate about a future that's kind to our planet and packed with possibilities."
    }
  ];

  return (
    <div className="homepage-sectionII main ">
      {/* Decorative elements */}
  
     {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAABFCAYAAACPKVzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA6SURBVHgB7dI7DgAgCANQ6cXFYxLuUj+JrsbJMPAWSKcOFXevJBWAipn1smGmbT3nfieBu6V097jeAX6ZNL1avyD4AAAAAElFTkSuQmCC" class="dots" alt=""/>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAABFCAYAAACPKVzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA6SURBVHgB7dI7DgAgCANQ6cXFYxLuUj+JrsbJMPAWSKcOFXevJBWAipn1smGmbT3nfieBu6V097jeAX6ZNL1avyD4AAAAAElFTkSuQmCC" class="dots2" alt=""></img>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABGCAYAAAANSP5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeSURBVHgB7cQxEQAADAIxroLq392DCEYyRMCfYvUzrggDtmWYGTYAAAAASUVORK5CYII=" class="line" alt=""/>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABGCAYAAAANSP5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeSURBVHgB7cQxEQAADAIxroLq392DCEYyRMCfYvUzrggDtmWYGTYAAAAASUVORK5CYII=" class="lineb" alt=""/>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACDCAYAAABMbXFaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgB7cQxEQAADAIxroLq392DCxYyRMCfYq16BmQHBDCW38R1AAAAAElFTkSuQmCC" class="line-2" alt=""></img>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAFlCAYAAAAqB4JxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgB5ZQxC4AgEIUlgoYGobbaChqC2vr/v6axrSFosJ7RDWGpeZsN130iz8c9USilBqG/s6uS81+jy9ClKD2wpC0T1hbCDpgZBCS6DWVEmWmLxp2wBRaEJbAxCDgc6eVZ2o/09+w/pm/Pq/eYbJ45otUCgi/aOXRMr54Zo5X/xuTgmSvaG/PQaE2eg6N9CDC+NFFex4hemkuAOdrxACgRwBI50JG1AAAAAElFTkSuQmCC" class="dotted" alt=""></img>
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAFlCAYAAAAqB4JxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgB5ZQxC4AgEIUlgoYGobbaChqC2vr/v6axrSFosJ7RDWGpeZsN130iz8c9USilBqG/s6uS81+jy9ClKD2wpC0T1hbCDpgZBCS6DWVEmWmLxp2wBRaEJbAxCDgc6eVZ2o/09+w/pm/Pq/eYbJ45otUCgi/aOXRMr54Zo5X/xuTgmSvaG/PQaE2eg6N9CDC+NFFex4hemkuAOdrxACgRwBI50JG1AAAAAElFTkSuQmCC" class="dotted2" alt=""></img> */}
      <div className="sectionII d-flex">
        {sections.map((section, index) => (
          <div key={index} className="sub-section">
            <video className='background-video' autoPlay muted loop>
        <source src="/images/nnt.mp4" type="video/mp4" />
       
      </video>
            <img src={section.imgSrc} className="subsection-image" alt={section.title} />
            <h1>{section.title}</h1>
            <p>{section.description}</p>
          </div>
        ))}
      </div>

      {/* Gradient overlays */}
      <div className="linear-gradient"></div>
      <div className="linear-gradient-II"></div>
     </div>
   
  );
}

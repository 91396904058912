import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Navbar from './components/home/navbar/Navbar';
import About from './components/about/About';
import Contact from './components/Contact/Contact';
import Star from './components/Animations/Star';
import FooterAnimation from './components/Footer/FooterAnimation';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
 
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 


    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />; 
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Star />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <FooterAnimation />
      <Footer />
    </BrowserRouter>
  );
}

export default App;

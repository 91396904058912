import React from 'react';
import "./herosection.css";
import { Link } from 'react-router-dom';

export default function HeroSection() {
    return (
        <main className='main'>
            <div className='video-background'>
                <video 
                    width="100%" 
                    height="100%" 
                    autoPlay 
                    muted 
                    loop
                    src="/images/video-animation1.mp4" // Update the path to your video file
                    type="video/mp4"
                />
            </div>
            <div className='textDiv'>
                <h1>We're Shaping Tomorrow, <br/> Crafting Progress with AI.</h1>
                <p>TajirAI champions the fusion of innovation and humanity to unlock limitless potential. We are more than <b>AI developers</b>; we are <b>visionaries</b> shaping the future, building <b>possibilities</b>, and driving <b>progress</b>.</p>
                    <Link
                                to="/about">
                <button className='button'> <i className='bi bi-box-arrow-up-right'></i> </button></Link>
            </div>
        </main>
    )
}

import React from 'react'
import HeroSection from './heroSection/HeroSection'
import Product from './ProductSection/Product'
import WhyUs from './WhyUsSection/WhyUs'
import Services from './ServicesSection/Services'
import Testimonials from './Testimonials/Testimonials'
import Item from './Item/Item'
import Newsection from '../Newsection/Newsection'

export default function Home() {
  return (
    <div>

      <HeroSection/>
      <Services/>
     
      <WhyUs/>
      <Item/>
      
      <Newsection/>
      <Product/>
      
     <Testimonials/>

    </div>
  )
}

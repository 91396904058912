import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const Contact = () => {
  const form = useRef(); // Create a ref for the form

  const sendEmail = (e) => {
    e.preventDefault();

    // Use the sendForm method with the correct parameters
    emailjs
      .sendForm(
        'service_edwip39', // Service ID
        'template_4seur5q', // Template ID
        form.current,       // Form reference
        'ts1j0km05yEoVZVrz' // User ID
      )
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset(); // Reset the form after successful submission
          // alert("Success Fully")
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <div className="contact-section">
      <div className="contact-content">
        {/* Left Side - Location Map */}
        

        {/* Right Side - Contact Form */}
        <div className="form-container">
          <center><h2>Contact Us</h2></center>
          <form ref={form} onSubmit={sendEmail}> {/* Attach the ref to the form */}
            <label>Name</label>
            <input type="text" name="name" placeholder="Your Name" required />

            <label>Email</label>
            <input type="email" name="email" placeholder="Your Email" required />

            <label>Subject</label>
            <input type="text" name="subject" placeholder="Subject" required />

            <label>Message</label>
            <textarea name="message"  required></textarea>

            <button className='send' type="submit">Send Message</button>
          </form>
          
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.1368239245257!2d73.11415827544762!3d31.437899974251827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392269000f09f7df%3A0x914dd5525bf8a336!2sTajir%20AI!5e0!3m2!1sen!2s!4v1735641048662!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="location-map"
          ></iframe>
        </div>
        
      </div>
     
      {/* Contact Information */}
     
     {/* <div className="Fix"> */}
     <div className="contact-info">
        
        <div className='Fix'>
        <div className="info-item">
          <i className="fab fa-whatsapp"></i>
          <a href="https://wa.me/923377638063" target="_blank" rel="noopener noreferrer">
            <p>+92 337 7638063</p>
          </a>
        </div>
        <div className="info-item">
          <a href='mailto:tajirailtd@gmail.com' target="_blank" rel="noopener noreferrer">
            <i className="fas fa-envelope"></i>
          </a>
          <a href='mailto:tajirailtd@gmail.com' target="_blank" rel="noopener noreferrer">
            <p>tajirailtd@gmail.com</p>
          </a>
        </div>
        <div className="info-item">
          <i className="fas fa-globe"></i>
          <p>www.Tajirai.com</p>
        </div>
        </div>
        <div className="info-item bb">
        <i className="fas fa-map-marker-alt"></i>
        <p>C4Q8+5M5, Street 7, Mansoorabad, Faisalabad, Punjab</p>
      </div>
      </div>


     </div>
    // </div>
  );
};

export default Contact;
